<template>
  <item
    :config="config"
    :open="open"
    :searchHighlights="searchHighlights"
    :listItem="listItem"
    :narrow="narrow"
    v-on="$listeners"
    class="gov-resolution-item"
  >
    <div v-if="listItem" class="item__closed-stage">{{ listItem.stage }}</div>
  </item>
</template>

<script>
import Item from '../../components/Item/Item';
import { GovResolutionItemPrepare } from './util/GovResolutionItemPrepare';

export default {
  components: { Item },
  metaInfo() {
    return {
      link: [
        {
          rel: 'canonical',
          href: `${window.location.origin}${this.$route.fullPath}`,
        },
      ],
    };
  },
  props: {
    open: Boolean,
    searchHighlights: {
      query: String,
      keywords: Array,
    },
    listItem: Object,
    narrow: Boolean,
  },
  data() {
    return {
      config: {
        routeName: 'gov-resolutions-item',
        routeList: 'gov-resolutions',
        route404: 'gov-resolutions',
        prepareData: GovResolutionItemPrepare,
        api: {
          body: 'gov_resolution_body',
          highlights: 'gov_resolutions/load_highlights',
          monitored: 'gov_resolutions/load_provisions_with_favorite_flag',
          additional: 'gov_resolution_provisions_and_versions',
          textVersions: {
            lastVersion: 'v1/gov_resolution_last_version_button',
            version: 'v1/gov_resolution_version',
          },
          descriptions: 'v1/gov_resolution_other_descriptions',
        },
        bookmark: {
          type: 'gov-resolutions',
          fetch: 'fetchGovResolutions',
          toggle: 'toggleGovResolutions',
        },
        labels: {
          linksTitle: 'Projekt na stronie:',
          linksDoneTitle: 'Uchwała na stronie:',
        },
      },
    };
  },
};
</script>

<style lang="scss">
@import './GovResolutionsItem';
</style>
